import { constantRoutes } from '@/router';

const state = {
  headerRoutes: [],
  footerRoutes: [],
};

const mutations = {
  IS_SHOW_LOGIN_DIALOG: (state, value) => {
  },
};

const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      commit('SET_ROUTES');
      resolve(constantRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
