import request from '@/utils/request';




export function getUrlKey(name) {
  // eslint-disable-next-line no-sparse-arrays
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}




 export function getNotificationList(data) {
  return request({
   // url: '/notification-notice/ext/page?current=1&size=10',
    url: '/notification-notice/ext/page',
    method: 'post',
    data,
  });
}


export function getTrainingList(data) {
  return request({
    url: '/train/ext/page',
    method: 'post',
    data,
  });
}


export function getSearchList(data) {
  return request({
    url: '/notification-notice/ext/notice-train/page',
    method: 'post',
    data,
  });
}


export function getNotificationDetail(params) {
  return request({
    url: '/notification-notice/ext/getNotificationNoticeById',
    method: 'get',
    params,
  });
}


export function getTrainingDetail(params) {
  return request({
    url: '/train/ext/getTrain',
    method: 'get',
    params,
  });
}


export function getWebMenu(params) {
  return request({
    url: '/external/website-menu/listByCode',
    method: 'get',
    params,
  });
}


export function getNewsMenu(params) {
  return request({
    url: '/external/website-menu/listTopMenu',
    method: 'get',
    params,
  });
}

export function getListByMenuId(params) {
  return request({
    url: '/external/website-content/listByMenuId',
    method: 'get',
    params,
  });
}

export function getSecondList(params) {
  return request({
    url: '/external/website-menu/listDetailsByParentId',
    method: 'get',
    params,
  });
}

export function getSecondDetailList(params) {
  return request({
    url: '/external/website-content/pageByMenuId',
    method: 'get',
    params,
  });
}


export function getDetailByIdAll(params) {
  return request({
    url: '/external/website-content/getDetailsById',
    method: 'get',
    params,
  });
}

