<template>
  <div class="web-footer">
    <div class="web-footer-inner">
        <h2>
          <span class="linkt">相关链接：</span>
          <el-link href='http://www.nhc.gov.cn/wjw/index.shtml' target="_blank">国家卫生健康委员会</el-link><span>|</span>
          <el-link href='http://www.chinapathology.cn/' target="_blank">国家卫健委病理质控中心</el-link><span>|</span>
          <el-link href='https://www.cnas.org.cn/' target="_blank">中国合格评定国家认可委员会</el-link><span>|</span>
          <el-link href='http://wjw.jiangsu.gov.cn' target="_blank">江苏省卫生健康委员会</el-link><span>|</span>
          <el-link href='http://www.ncis.cn/home' target="_blank">国家医疗质量管理与控制信息网</el-link><span>|</span>
          <el-link href='https://www.cap.org/' target="_blank">美国病理学家协会</el-link>
        </h2>
        <h2>
          Copyrigth © 2006-2021
          <span>|</span>
          <el-link href='https://beian.miit.gov.cn/' target="_blank">苏ICP备19050861号-3</el-link>
          <span>|</span>
           <img  src='~@/assets/images/safe.png' class="foot-icon"  /> <el-link href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003607' target="_blank">苏公网安备 32059002003607号</el-link>
        </h2>
        <h3></h3>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
  },
  watch: {
  },
  created() {},
}
</script>



