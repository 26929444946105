const state = {
  activeIndex: 0,
  isIndexFooter: false,
  isPersonalPage: false,
  personalAcitivePath: '/myProfile',
  headerTitle: '', // 首页默认没有标题
};
const mutations = {
  IS_INDEX_FOOTER: (state, value) => {
    state.isIndexFooter = value;
  },
  PERSONAL_PATH: (state, value) => {
    state.personalAcitivePath = value;
  },
  HEADET_TITLE: (state, value) => {
    state.headerTitle = value;
  },
  IS_PERSONALl_PAGE: (state, value) => {
    state.isPersonalPage = value;
  },
  CHANGE_ACTIVE_INDEX(state, index) {
    state.activeIndex = index
  }
};
const actions = {
  changeFootertheme({ commit }, value) {
    commit('IS_INDEX_FOOTER', value);
  },
  changePersonalPath({ commit }, value) {
    commit('PERSONAL_PATH', value);
  },
  changeHeaderTitel({ commit }, value) {
    commit('HEADET_TITLE', value);
  },
  changePersonalPage({ commit }, value) {
    commit('IS_PERSONALl_PAGE', value);
  },
  changeActiveIndex({ commit }, index) {
    commit('CHANGE_ACTIVE_INDEX', index)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
