import ElementUI from "element-ui";
import "@/assets/styles/index.scss";
import * as validates from "@/utils/validate";
import * as webcommon from "@/utils/common";

import "@/components"; // 组件
export default {
  async install(Vue, options) {
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    // https://cn.vuejs.org/v2/api/#productionTip
    // Vue.config.productionTip = false
    // 当前环境
    Vue.prototype.$env = process.env.NODE_ENV;
    // 当前版本
    Vue.prototype.$version = process.env.VUE_APP_VERSION;
    // 构建时间
    Vue.prototype.$buildTime = process.env.VUE_APP_BUILD_TIME;
    // 当前的 上传文件地址
    Vue.prototype.$baseUploadUrl = process.env.VUE_APP_BASE_UPLOAD_URL;
    // 验证工具类
    Vue.prototype.$validate = validates;
    // 格式化工具类
    Vue.prototype.$webcommon = webcommon;
    Vue.use(ElementUI, {
      size: "medium"
    });
  }
};
