// import defaultSettings from '@/settings'

const title = '江苏省病理专业质量控制中心';

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
