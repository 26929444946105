import Cookies from 'js-cookie';
const TokenKey = 'feign';

export function getToken() {
   // TODO: 事先登录 方便开发主页面
  // if (process.env.NODE_ENV === 'development') {
  //   return '8cb1ca46-ba7a-44a0-b134-0b4ef708ed67'
  // }

   return Cookies.get(TokenKey);
 // return 'feign'
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
