import * as validates from '@/utils/validate';
import { toDate, padLeftZero, formatUnitReplace } from '@/utils/common';
export { parseTime, formatDataTime } from '@/utils';
export { formatUnitReplace } from '@/utils/common';
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}
/**
 * 校验是否是当天true
 * @param {string} time
 * @returns {boolean}
 */
export function validIsToday(time) {
  if (arguments.length === 0 || validates.isEmpty(time)) {
    return null;
  }
  if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
    time = parseInt(time);
  }
  if (typeof time === 'number' && time.toString().length === 10) {
    time = time * 1000;
  }
  // ie 11 new Date()不支持yy-MM-dd的形式 需要改为 yy/MM/dd  angel 2019/07/23
  if (/-/.test(time)) {
    time = time.replace(new RegExp(/-/gm), '/');
  }
  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;
  if (diff > 3600 * 24 * 1) {
    return true;
  } else {
    return false;
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 *
 * @param {*} srcDate
 * @param {*} fmt
 */
export function dateFormat(srcDate, fmt = 'yyyy-MM-dd') {
  if (!srcDate) {
    console.log('时间格式不正确：', srcDate);
    return '';
  }
  if (typeof (srcDate === 'string') && srcDate.length > 19) {
    srcDate = srcDate.substr(0, 19);
  }
  const date = toDate(srcDate);
  if (date === 'Invalid Date') {
    console.log('时间格式不正确：', srcDate);
    return '';
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length),
    );
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + '';
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str),
      );
    }
  }
  return fmt;
}

// 格式化备注浮窗
export function formaterRemark(remark) {
  // console.log(remark)
  if (remark) return remark.replace(/\|/gi, '<br/>');
}
/**
 * @description 数字保留两位小数，默认保留两位小数
 * @param {String | Number} cellValue
 * @param {Number} number 保留几位小数
 * @param {String} unit 单位
 */
export function numberToFixed(cellValue, number = 2, unit = '') {
  const value = formatUnitReplace(cellValue);
  return isNaN(value) ? value : Number(value).toFixed(number) + unit;
}
