import { dateFormat } from '@utils/common.js';
import md5 from 'js-md5'


let timer = dateFormat(new Date(), 'yyyyMMdd')

const commonSets = {
   customerId: '1395562462765387777',
   key: md5(`deepsight${timer}`),
  }


// module.exports = {
//    commonSets: {
//     customerId: '1395562462765387777',
//     key: md5(`deepsight${timer}`),
//    }
// };

export {
  commonSets
}
