import Vue from 'vue';
import Router from 'vue-router';
/* Layout */
import Layout from '@/layout/layout';

Vue.use(Router);
export const constantRoutes = [
  {
    path: '/',
    name: '首页',
    redirect: '/index',
    component: Layout,
    children: [
      {
        name: 'Index',
        path: '/index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: '首页',
        },
      },
      {
        name: 'About',
        path: '/about',
        component: () => import('@/views/about/index'),
        meta: {
          title: '中心简介',
        },
      },
      {
        name: 'AboutCenter',
        path: '/aboutCenter',
        component: () => import('@/views/about/aboutCenter'),
        meta: {
          title: '中心介绍',
        },
      },
      {
        name: 'Notice',
        path: '/notice',
        component: () => import('@/views/notice/noticeList'),
        meta: {
          title: '通知列表',
        },
      },
      {
        name: 'NoticeDetail',
        path: '/noticeDetail',
        component: () => import('@/views/notice/noticeDetail'),
        meta: {
          title: '通知公告',
        },
      },
      {
        name: 'Training',
        path: '/training',
        component: () => import('@/views/training/trainingList'),
        meta: {
          title: '培训学习',
        },
      },
      {
        name: 'TrainingDetail',
        path: '/trainingDetail',
        component: () => import('@/views/training/trainingDetail'),
        meta: {
          title: '培训学习内容',
        },
      },
      {
        name: 'News',
        path: '/news',
        component: () => import('@/views/news/newsList'),
        meta: {
          title: '新闻活动',
        },
      },
      {
        name: 'NewsDetail',
        path: '/newsDetail',
        component: () => import('@/views/news/newsDetail'),
        meta: {
          title: '新闻活动内容',
        },
      }
    ]
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
  },
  {
    path: '*',
    redirect: '/404'
  },
];

const createRouter = () =>
  new Router({
    base: '/',
    mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
