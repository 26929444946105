/* eslint-disable */
import * as validates from '@/utils/validate';
/**
 * 格式化显示结果(增加单位，为空时替换显示)
 * @param {object} obj 要转换的对象
 * @param {string} unit 单位
 * @param {Boolean} hasBracket 单位是否加括号 默认false
 * @returns {string} txt 如果为空显示的结果，默认--
 */
export function formatUnitReplace(
  obj,
  unit = '',
  hasBracket = false,
  txt = '--'
) {
  if (validates.isEmpty(obj)) {
    return txt;
  }
  // 单位是否加括号
  if (hasBracket && !validates.isEmpty(unit)) {
    unit = `(${unit})`;
  }
  return obj + unit;
}
/**
 * 转换成时间戳
 * @param {string} srcDate 要转换的时间
 */
export function toTime(srcDate) {
  const date = toDate(srcDate);
  if (date === 'Invalid Date') {
    console.log('时间格式不正确：', srcDate);
    return '';
  }
  return date.getTime();
}
/**
 * 转换成Date对象
 * @param {*} srcDate 要转换的时间
 */
export function toDate(srcDate) {
  if (!srcDate) {
    return 'Invalid Date';
  }
  let date = srcDate;
  if (!(date instanceof Date)) {
    if (typeof date === 'string') {
      date = date.replace(/-/g, '/');
    }
    date = new Date(date);
  }
  return date;
}
/**
 * 格式化日期和时间
 * @param {Object} srcDate  要转换的时间
 * @param {String} fmt  要转换的时间格式，例如： yyyy-MM-dd HH:mm:ss; yy/MM/dd HH:mm
 */
export function dateFormat(srcDate, fmt) {
  if (!srcDate) {
    console.log('时间格式不正确：', srcDate);
    return '';
  }
  if (typeof (srcDate === 'string') && srcDate.length > 19) {
    srcDate = srcDate.substr(0, 19);
  }
  const date = toDate(srcDate);
  if (date === 'Invalid Date') {
    console.log('时间格式不正确：', srcDate);
    return '';
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + '';
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}
export function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

/**
 * 比较两个时间大小
 * @param {Object} beginDate  开始时间
 * @param {Object} endDate 结束时间
 * @return {int} 返回大于1、等于0、小于-1
 */
export function compareDate(beginDate, endDate) {
  const date1 = toDate(beginDate);
  const date2 = toDate(endDate);
  if (date1 === 'Invalid Date' || date2 === 'Invalid Date') {
    return 0;
  }
  const time1 = date1.getTime();
  const time2 = date2.getTime();
  if (time1 === time2) {
    return 0;
  }
  return time1 > time2 ? 1 : -1;
}

// 格式化备注浮窗
export function formaterRemark(remark) {
  // console.log(remark)
  if (remark) return remark.replace(/\|/gi, '<br/>');
}
// elementUI el-table-column的formatter
export function elTableColFormatter(row, column, cellValue) {
  return formatUnitReplace(cellValue);
}

export function elTableColFormaterRemark(newVal) {
  return formatUnitReplace(newVal);
}
