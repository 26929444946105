import axios from 'axios'


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = "application/json";
    config.data = JSON.stringify(config.data);
    return config
  },
  error => {
    console.log(error) // for debug
    return new Error(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const dataAxios = response.data
      // 这个状态码是和后端约定的
      const {
        msg
      } = dataAxios
      // 根据 errorCode 进行判断
      if (msg === undefined) {
        // 如果没有 errorCode 代表这不是项目后端开发的接口
        return dataAxios

      } else {
        // 有 errorCode 代表这是一个后端接口 可以进行进一步的判断
        switch (msg) {
          case '0':
            // [ 示例 ] errorCode === '0' 代表没有错误
            return dataAxios
          case '4002':
            return dataAxios
          default:
            // 用户自定义错误
            break
        }
        // 不是正确的 code
        return dataAxios
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break
        case 401:
          error.message = '未授权，请登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器内部错误'
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '网关错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网关超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
          break
      }
    } else if (error && error.request) {
      if (error.request.readyState === 4 && error.request.status === 0) {
        // 我在这里重新请求
        error.message = '接口响应超时'
      }
    }
    console.log(error.message)
    // return Promise.reject(error)
    return new Error(error)
  }
)

export default service
