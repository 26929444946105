<template>
  <div class="app-main">
    <router-view />
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {}
  },

  created() {},

  methods: {},
}
</script>
<style scoped lang="scss">

</style>
<style lang="scss">
// .app-main{
//   padding-bottom:200px
// }
</style>
