/* eslint-disable */
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import {
//   getToken
// } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({
  showSpinner: false,
})

const whiteList = [
  '/',
  '/index',
  '/about',
  '/aboutCenter',
  '/notice',
  '/noticeDetail',
  '/trainingDetail',
  '/training',
  '/news',
  '/newsList',
  '/404'
] // 不需要登录路由白名单
router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  NProgress.start()
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    next({
      path: '/',
    })
    NProgress.done()
  }

})
router.afterEach(() => {
  NProgress.done()
})
