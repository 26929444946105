import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/permission'; // 权限控制
import * as filters from './filters'; // 指令
import mPlugs from '@/plugins/common'; // 核心插件
import $ from "jquery"
window.$ = $
import md5 from 'js-md5'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
Vue.use(mPlugs);
Vue.prototype.$md5 = md5
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
