const getters = {
  activeIndex: state => state.settings.activeIndex,
  sidebar: state => state.app.sidebar,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  isIndexFooter: state => state.settings.isIndexFooter,
  isPersonalPage: state => state.settings.isPersonalPage,
  personalAcitivePath: state => state.settings.personalAcitivePath,
  headerTitle: state => state.settings.headerTitle,
}
export default getters
