
import {
  getToken,
  removeToken,
 // setToken
} from '@/utils/auth';
// import { resetRouter } from '@/router';

const state = {
  token: getToken(),
  userInfo: null,
  toolAuth: null,
  appKey: null,
  orgId: null,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_APP_KEY: (state, appKey) => {
    state.appKey = appKey;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_ORGID: (state, orgId) => {
    state.orgId = orgId;
  },
  SET_TOOAUTH: (state, data) => {
    state.toolAuth = data.toolAuth;
  },
};

const actions = {
  // user login
  // login({
  //   commit
  // }, userInfo) {
  //   const {
  //     mobile,
  //     password,
  //     loginType
  //   } = userInfo
  //   return new Promise((resolve, reject) => {
  //     getQsLoginIn({
  //         mobile: mobile.trim(),
  //         password: password,
  //         loginType: loginType
  //       })
  //       .then(res => {
  //         if (res.code === 0) {
  //           commit('SET_TOKEN', res.result.loginToken)
  //           setToken(res.result.loginToken)
  //         }
  //         resolve(res)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
  // getAppKey
  // getTmAppKey({
  //   commit
  // }) {
  //   return new Promise((resolve, reject) => {
  //     getAppKey()
  //       .then(res => {
  //         if (!res) {
  //           reject('未获取到appkey');
  //         }
  //         if (res && res.appKey) {
  //           commit('SET_APP_KEY', res.appKey);
  //           resolve(res);
  //         } else {
  //           reject('未获取到appkey');
  //         }
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  // get user info
  // getInfo({
  //   commit,
  //   state
  // }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token)
  //       .then(data => {
  //         if (data) {
  //           commit('SET_USERINFO', data);
  //           commit('SET_ORGID', data.orgId);
  //           resolve(data);
  //         } else {
  //           reject('未登录，请先登录.');
  //         }
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  // user logout
  logout({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '');
      commit('SET_USERINFO', null);
      commit('SET_APP_KEY', null);
      commit('SET_ORGID', null);
      removeToken();
      resolve();
    });
  },
  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_USERINFO', null);
      commit('SET_ORGID', null);
      removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
