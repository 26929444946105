<template>
  <div id="app">
    <router-view ref="routerAll" />

  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  mounted() {

  },
  watch: {

  },
  created() {},
  methods: {

  },
}
</script>
<style scoped></style>
