<template>
  <div class="mainw pdb40">
    <div class="search-no" v-show="resultlist.length === 0">
      <img src='~@/assets/images/nodata.png'  class=""  />
      <h1>暂无搜索结果</h1>
    </div>
    <div class="result-list-wrap" >
      <h4>搜索结果<b>找到约{{resultlist.length}}条记录</b></h4>
      <ul>
        <li  v-for="(item, index) in resultlist" :key="index" @click="toDetail(item)">
          <h1>[{{item.type===1?'通知公告':'培训学习'}}] {{item.title}}</h1>
          <p v-if="item.content" v-html="item.content"></p>
          <h2>{{item.publishTime}}</h2>
        </li>
      </ul>

    </div>
      <div class="page-wrap">
      <el-pagination
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import New from '@/new.js'
import { mapGetters, mapActions } from 'vuex'
import { getSearchList } from '@api/web.js'
import { commonSets } from '@utils/commonSets.js';
export default {
  components: {},
  data() {
    return {
      resultlist: [],
      current: 1,
      size: 10,
      total: 0,
    }
  },
  mounted() {
  },
  computed: {
   ...mapGetters(['activeIndex']),
  },

  created() {
    New.$on('startResult', (value) => {
      this.getResult(value);
    });
  },
  methods: {
        // 跳转路由
    ...mapActions(['settings/changeActiveIndex']),
    getResult() {
      const data = {
        customerId: commonSets.customerId,
        key: commonSets.key,
        title: this.$parent.title,
        current: this.current,
        size: this.size
      }
      getSearchList(data).then(res => {
        if (res.success) {
          this.resultlist = res.data.records
        }
      })
    },
            // 翻页
    handleCurrentChange(val) {
      this.current = val
      this.getResult()
    },
    toDetail(e) {
       this.$parent.isSearch = false
       if (e.type === 1) {
          this['settings/changeActiveIndex'](2)
          this.$router.push({
            path: '/noticeDetail',
            name: 'NoticeDetail',
            query: {
              noticeId: e.id
            }
          })
       } else {
          this['settings/changeActiveIndex'](3)
          this.$router.push({
            path: '/trainingDetail',
            name: 'TrainingDetail',
            query: {
              trainId: e.id,
              trainTitle: e.title
            }
          })
       }
    }
  },

}
</script>
