<template>
<div>
  <div class="top-menu-wrap" >
       <div class="top-line">
        <router-link to="/index">
          <span class="logo-name">江苏省病理专业质量控制中心</span><br>
          <span class="logo-name2">Pathology Quality Control Center of Jiangsu Province</span>
        </router-link>
        <div class="top-serch">
          <el-input
          v-model="title"
          clearable
          placeholder="搜索培训或公告"
          @keyup.enter.native="handleSearch()"
          >
          <i
            class="el-icon-search el-input__icon"
            slot="prefix"
            >
          </i>
        </el-input>
        </div>
        <div class="top-serch-btn">
           <el-button plain class=""  @click="handleSearch()"   >搜索</el-button>
        </div>
       </div>

        <div class="top-menu " >
          <div class="mainw">
          <ul>
            <li v-for="(name, iname) in nameList" :key="iname" @click="handlerIndex(iname)" :class="{ topMenuOn: activeIndex === iname }">
              <i class='el-icon-s-home' v-show="iname === 0"></i>{{name}}
            </li>
          </ul>
          </div>

        </div>

  </div>
  <div class="top-menu-blank"></div>

</div>

</template>
<script>
import New from '@/new.js'
import { mapGetters, mapActions } from 'vuex'
import { wholeDates } from './searchTemp';
export default {
  name: '',
  components: {},
  data() {
    return {
      nameList: ['网站首页','中心简介','通知公告','培训学习','新闻活动','上报平台'],
      autoList: wholeDates.autoList,
      title: ''
    }
  },
  created() {
  },
   mounted() {
    },
  computed: {
   ...mapGetters(['activeIndex']),
  },

  methods: {
   handleSearch() {
     this.$parent.isSearch = true
     this.$parent.title = this.title
     this.$nextTick(function() {
        New.$emit('startResult', this.title);
     })
   },
    // 跳转路由
    ...mapActions(['settings/changeActiveIndex']),


    // 路径切换
    handlerIndex(e) {
      this.$parent.isSearch = false
      this.searchKey = ''
      switch (e) {
          case 0:
              this['settings/changeActiveIndex'](0)
              this.$router.push('/index').catch(err => {err})
              document.querySelector('#app').scrollTop = 0
              break
          case 1:
              this['settings/changeActiveIndex'](1)
              this.$router.push('/about').catch(err => {err})
              document.querySelector('#app').scrollTop = 0
              break
          case 2:
              this['settings/changeActiveIndex'](2)
              this.$router.push('/notice').catch(err => {err})
              document.querySelector('#app').scrollTop = 0
              break
          case 3:
              this['settings/changeActiveIndex'](3)
              this.$router.push('/training').catch(err => {err})
              document.querySelector('#app').scrollTop = 0
              break
          case 4:
              this['settings/changeActiveIndex'](3)
              this.$router.push('/news').catch(err => {err})
              document.querySelector('#app').scrollTop = 0
              break
          case 5:
              this['settings/changeActiveIndex'](4)
              window.open('http://portal.jspqcc.com/', '_blank')
              document.querySelector('#app').scrollTop = 0
              break
          default:
              this['settings/changeActiveIndex'](0)
              this.$router.push('/index').catch(err => {err})
              document.querySelector('#app').scrollTop = 0
              break
        }
    },



    querySearch(queryString, cb) {
      var autoList = this.autoList;
      var results = queryString ? autoList.filter(this.createFilter(queryString)) : autoList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

      handleIconClick(ev) {
   //     console.log(ev);
      }

  },
}

</script>
<style lang="scss" scoped>
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
