<template>
  <div class="app-wrapper">
    <web-header ref="topbar" />
    <app-main ref="outbox" v-if="!isSearch"/>
    <web-search ref="webSearch" v-if="isSearch" />
    <web-footer />
  </div>
</template>
<script>
import { AppMain, webFooter, webHeader, webSearch  } from './components'
export default {
  name: 'Layout',
  components: {
    AppMain,
    webFooter,
    webHeader,
    webSearch
  },
  data() {
    return {
      isSearch: false,
      title: null
    }
  },
  mounted() {},
  methods: {},
}
</script>
